<template>
	<div class="swiperBox pointer swiperBoxHome">
		<el-carousel>
			<el-carousel-item v-for="(item, index) in bnnerLists" :key="index">
				<img
					:src="item.pic"
					:key="item.pic"
					style="object-fit: cover"
					alt=""
					@click="goView(item.id)"
				/>
			</el-carousel-item>
		</el-carousel>
		<div class="swiperBox_bottom">
			<div
				class="swiperBox_bottom_item"
				v-for="(item, bottomIndex) in bottomLists"
				:key="bottomIndex"
			>
				<div class="swiperBox_bottom_item_img" @click="goView(item.id)">
					<img :src="item.pic" :key="item.pic" alt="" />
				</div>
				<div class="swiperBox_bottom_item_content pointer hovercolor">
					<span
						class="swiperBox_bottom_item_title hovercolor"
						@click="goView(item.id)"
					>
						{{
							localLanguage == 2
								? item.title
									? item.title
									: item.title
								: item.title
						}}
					</span>
					<span class="swiperBox_bottom_item_time" v-time="item.release_time">
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../../components/pc/leftTab";
export default {
	props: ["bnnerLists", "bottomLists"],
	data() {
		return {
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		goView(id) {
			if (id == 0) {
				this.$router.push({
					path: "/hk_annualceremony",
				});
				return;
			}
			this.$router.push({
				path: "/gw_detail",
				query: {
					id,
				},
			});
		},
	},
};
</script>

<style scoped>
.swiperBox {
	width: 100%;
	height: 371px;
	background: white;
	border-radius: 15px;
}
.el-carousel--horizontal {
	height: 240px;
}

.swiperBox img {
	width: 100%;
	border-radius: 15px;
}

.swiperBox_bottom {
	width: 100%;
	height: 131px;
	border-radius: 15px;
	flex-direction: row;
	display: flex;
}

.swiperBox_bottom_item {
	display: flex;
	align-items: center;
	margin-top: 37px;
	margin-left: 15px;
	margin-right: 24px;
	height: 60px;
}
.swiperBox_bottom_item_img {
	width: 97px;
	height: 60px;
	object-fit: cover;
}
.swiperBox_bottom_item_img img {
	width: 97px !important;
	height: 60px !important;
	object-fit: cover;
	border-radius: 8px !important;
}

.swiperBox_bottom_item_content {
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.swiperBox_bottom_item_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	height: 41px;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.swiperBox_bottom_item_time {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	margin-top: 5px;
	/* 字段三级 */
	color: #b2b2b2;
}

@media screen and (max-width: 950px) {
	.swiperBox {
		height: calc(100vw * 90 / 225);
		overflow: hidden;
	}
}
</style>
<style>
.swiperBoxHome .el-carousel__container {
	height: 240px !important;
}
.el-carousel__item {
	border-radius: 15px;
}
@media screen and (max-width: 950px) {
	.swiperBoxHome .el-carousel__container {
		height: calc(100vw * 90 / 225) !important;
	}
}
</style>
